import React from "react";
import styled from "styled-components";

import Section from "../../../components/Section";
import { oneLineTrim } from "common-tags";

const naverKey = "hnrq0lgpf7";
const marker = (x: string, y: string) => `type:d|size:mid|color:Orange|pos:${x} ${y}`;
const maps = {
  yeonsan: {
    src: oneLineTrim`
      https://naveropenapi.apigw.ntruss.com/map-static/v2/raster-cors
      ?crs=EPSG:4326
      &w=312
      &h=201
      &center=129.0868676,35.1857657
      &level=17
      &X-NCP-APIGW-API-KEY-ID=${naverKey}
      &public_transit=true
      &markers=${marker("129.0868676", "35.1857657")}
    `,
    name: "부산본점(연산동)",
    address: "부산광역시 연제구 고분로 41-1, 2층",
    call: "051-866-3349",
    time: "매일 08:00 - 24:00",
    info: "주차장, 아기의자, 엘레베이터, 100명 단체석",
    href: "https://naver.me/5iYlTIP7",
  },
  myoungjang: {
    src: oneLineTrim`
      https://naveropenapi.apigw.ntruss.com/map-static/v2/raster-cors
      ?crs=EPSG:4326
      &w=312
      &h=201
      &center=129.1022214,35.2056195
      &level=17
      &X-NCP-APIGW-API-KEY-ID=${naverKey}
      &public_transit=true
      &markers=${marker("129.1022214", "35.2056195")}
    `,
    name: "동래명장점",
    address: "부산광역시 동래구 반송로 290, 1층",
    call: "051-959-4753",
    time: "매일 08:00 - 22:00",
    info: "주차장, 아기의자",
    href: "https://naver.me/5I4KiwoF",
  },
  geojae: {
    src: oneLineTrim`
      https://naveropenapi.apigw.ntruss.com/map-static/v2/raster-cors
      ?crs=EPSG:4326
      &w=312
      &h=201
      &center=128.7119864,35.0065428
      &level=14
      &X-NCP-APIGW-API-KEY-ID=${naverKey}
      &markers=${marker("128.7119864", "35.0065428")}
    `,
    name: "한화벨버디어점",
    address: "거제시 장목면 거제북로 2501-40, 고메이",
    call: "055-951-4205",
    time: "매일 07:00 - 20:30",
    info: "주차장, 아기의자",
    href: "https://naver.me/5MPl5exL",
  },
};

function MapCard(props: { value: typeof maps[keyof typeof maps] }) {
  return (
    <MapCardContainer>
      <a href={props.value.href} target="_blank">
        <MapImage src={props.value.src} />
      </a>
      <MapTitle>
        <b>{props.value.name}</b>
      </MapTitle>
      <MapSubTitle>주소</MapSubTitle>
      <MapText>{props.value.address}</MapText>
      <MapSubTitle>전화번호</MapSubTitle>
      <MapText>{props.value.call}</MapText>
      <MapSubTitle>이용시간</MapSubTitle>
      <MapText>{props.value.time}</MapText>
      <MapSubTitle>이용안내</MapSubTitle>
      <MapText>{props.value.info}</MapText>
    </MapCardContainer>
  );
}

export function Section2() {
  return (
    <Section>
      <ContentContainer>
        <ContentWrapper>
          <MapCard value={maps.yeonsan} />
          <Border />
          <MapCard value={maps.myoungjang} />
          <Border />
          <MapCard value={maps.geojae} />
        </ContentWrapper>
      </ContentContainer>
    </Section>
  );
}

const ContentContainer = styled.div`
  width: 100%;
  padding: 0;
`;

const ContentWrapper = styled.div`
  width: 312px;
  height: 100%;
  margin: 0 auto;
  padding: 80px 0;
`;

const MapCardContainer = styled.div`
  margin: 0;
`;

const MapImage = styled.img`
  width: 312px;
  height: 201px;
  margin-bottom: 24px;
`;

const MapTitle = styled.p`
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.41px;
  color: #212529;
  margin-bottom: 8px;
`;

const MapSubTitle = styled.p`
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.34px;
  color: #495057;
  margin-top: 16px;
`;

const MapText = styled.p`
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.7;
  letter-spacing: -0.34px;
  color: #212529;
`;

const Border = styled.div`
  width: 312px;
  height: 1px;
  background-color: #dee2e6;
  margin: 24px 0;
`;
