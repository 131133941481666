import React from "react";

import Layout from "../../../components/Layout";
import Header from "../../../components/Header";
import Footer from "../../../components/Footer/Desktop";

import { Section1 } from "./section1";
import { Section2 } from "./section2";

export function MapPage() {
  return (
    <Layout>
      <Header />
      <Section1 />
      <Section2 />
      <Footer />
    </Layout>
  );
}
